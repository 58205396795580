body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFAFA;

}
.ReactModal__Body--open{
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}


.ReactModal__Overlay{
  z-index: 102 !important;
}


iframe{
  border: 0px;
}

/* forTab */
.EventTab {
  padding: 0 100px;
}
.EventTab .MuiTabs-flexContainer{
  justify-content: center;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  max-width: 1280px;
}
.MuiTouchRipple-root{
  display: none;
}
.EventTab .MuiTab-root{
  min-width: inherit !important;
  padding: 8px 0 !important;
  margin: 0 40px !important;
}
.EventTab .MuiTab-wrapper, .InCardTab .MuiTab-wrapper{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  color: #66676E;
}
.EventTab .Mui-selected .MuiTab-wrapper,
.InCardTab .Mui-selected .MuiTab-wrapper{
  color: #3578F1;
}
.MuiTabs-indicator{
  background-color: #3578F1 !important;
}
.InCardTab .MuiTabs-indicator{
  display: none;
}
.InCardTab.MuiTabs-root{
  border-bottom: 1px solid rgba(0,0,0,0.1);
  min-height: inherit;
  padding: 8px 12px;
}
.InCardTab .MuiTab-root{
  min-width: inherit;
  padding: 0 30px;
}
.InCardTab .MuiTabs-flexContainer{
  justify-content: center;
}
@media (max-width: 768px){
  .EventTab {
    padding: 0 16px;
    overflow: scroll;
  }
  .EventTab .MuiTab-root{
    margin: 0 16px !important;
  }
  .EventTab .MuiTabs-flexContainer{
    justify-content: start;
    overflow-x: scroll;
  }
}
@media (max-width: 568px){
  .InCardTab .MuiTab-root{
      padding: 0;
      margin: 0 16px;
      border-bottom: 2px solid transparent;
  }
  .InCardTab .Mui-selected{
    border-bottom: 2px solid #3578F1;
  }
  .EventTab .MuiTab-root.Mui-selected{
    border-bottom: 2px solid #3578F1;
  }
  .EventTab .MuiTabs-indicator{
    display: none;
  }
}

.react-swipeable-view-container > div[aria-hidden="false"] {
  height: 100%;
}
.react-swipeable-view-container > div[aria-hidden="true"] {
  height: 0;
}




.sticky-container > div{
  height: 100%;
}

/* forMD */
.info{
  color: #9B9B9B;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.br{
  padding-bottom: 15px;
}

h6{
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.ReactModal__Content{
  width: 100% !important;
  max-width: 601px !important;
  max-height: 520px !important;
  border : 1px solid rgba(255, 255, 255, 0.3) !important;
}

.mobile{
  display: none !important;
}

/* Modal */
@media (max-width: 568px){
  .web{
    display: none !important;
  }
  .mobile{
    display: block !important;
  }

  .ReactModal__Content{
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    border: 0px !important;
    box-shadow: inherit !important;
    border-radius: inherit !important;
    border : 1px solid rgba(255, 255, 255, 0.3) !important;
  }
  .ReactModal__Overlay{
    background-color: transparent !important;
  }
  .EventTab .MuiTabs-scroller{
    /* overflow: scroll; */
  }
  .checkbox-container {
    display: flex;
    align-items: center; /* 垂直居中对齐 */
    gap: 20px; /* 用于设置两个复选框之间的间隔 */
  }
}
